import * as React from 'react';
import {FlashMessage} from './CheckoutPage/CheckoutPage';
import {Alerts} from '../../ui/Alerts/Alerts';
interface FlashMessageBoxProps {
    flashMessage: FlashMessage;
}
export const FlashMessageBox = ({flashMessage}: FlashMessageBoxProps) => {
    if (flashMessage.type === 'warn') {
        return (
            <Alerts
                message={flashMessage.message}
                variant="warning"
            />
        );
    }
    if (flashMessage.type === 'info') {
        return (
            <Alerts
                message={flashMessage.message}
                variant="info"
            />
        );
    }
    return (
        <Alerts
            message={flashMessage.message}
            variant="danger"
        />
    );
};
